<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理(新版)</a>
          <i>></i>
          <a href="javascript:;" @click="getData()" class="cur-a"
            >医院端订单列表</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="是否开票" class="searchboxItem ci-full">
              <span class="itemLabel">是否开票:</span>
              <el-select
                size="small"
                v-model="searchData.complete"
                placeholder="请选择是否开票"
                clearable
              >
                <el-option
                  v-for="item in invoiceOrNotList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="支付方式" class="searchboxItem ci-full">
              <span class="itemLabel">支付方式:</span>
              <el-select
                size="small"
                v-model="searchData.paymentMethod"
                placeholder="请选择支付方式"
                clearable
              >
                <el-option
                  v-for="item in paymentMethodList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="交易状态" class="searchboxItem ci-full">
              <span class="itemLabel">交易状态:</span>
              <el-select
                size="small"
                v-model="searchData.orderStatus"
                placeholder="请选择交易状态"
                clearable
              >
                <el-option
                  v-for="item in transactionStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否上传凭证" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >是否上传凭证:</span
              >
              <el-select
                size="small"
                v-model="searchData.voucher"
                placeholder="请选择是否上传凭证"
                clearable
              >
                <el-option
                  v-for="item in uploadVoucherList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">订单日期:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.orderTme"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="支付时间" class="searchboxItem ci-full">
              <span class="itemLabel">支付时间:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.paymentTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="getExport()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="margin: 15px 0.675rem 5px">
          <span>订单总金额：{{ TotalForm.orderAmountCount || 0 }} 元</span>
          <span style="margin: 0 30px"
            >订单数量：{{ TotalForm.orderNumTotal || 0 }} 笔</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="订单编号"
                align="center"
                prop="orderSn"
                show-overflow-tooltip
                width="160"
                fixed
              />
              <el-table-column
                label="订单支付编号"
                align="center"
                prop="orderPaymentNumber"
                show-overflow-tooltip
                width="240"
                ><template slot-scope="scope" class="flexcc">
                  {{ scope.row.orderPaymentNumber || "--" }}
                </template></el-table-column
              >
              <el-table-column
                label="购买单位"
                align="center"
                prop="compName"
                show-overflow-tooltip
                width="200"
              />
              <el-table-column
                label="支付方式"
                align="center"
                prop="paymentMethod"
                show-overflow-tooltip
                width="120"
                ><template slot-scope="scope" class="flexcc">
                  {{
                    $setDictionary("PAYMENT_METHOD", scope.row.paymentMethod)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单时间"
                align="center"
                prop="orderTime"
                show-overflow-tooltip
                width="160"
              />
              <el-table-column
                label="支付时间"
                align="center"
                prop="payTime"
                show-overflow-tooltip
                width="140"
              />
              <el-table-column
                label="订单金额"
                align="center"
                prop="paymentMoney"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope" class="flexcc">
                  <span
                    v-if="scope.row.paymentMoney"
                    style="color: red; font-size: 16px; font-weight: 600"
                    >￥{{ scope.row.paymentMoney }}</span
                  >
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="交易状态"
                align="center"
                prop="orderStatus"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope" class="flexcc">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否上传凭证"
                align="center"
                prop="voucherKey"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope" class="flexcc">
                  {{ scope.row.voucherKey || scope.row.voucherKey == ''? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否开发票"
                align="center"
                prop="complete"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope" class="flexcc">
                  {{ scope.row.complete ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column
                label="发票类型"
                align="center"
                prop="invoiceType"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope" class="flexcc">
                  {{
                    $setDictionary("INVOICE_TYPE", scope.row.invoiceType) ||
                      "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="发票编号"
                align="center"
                prop="invoiceNo"
                show-overflow-tooltip
                width="140"
              >
                <template slot-scope="scope" class="flexcc">
                  {{ scope.row.invoiceNo ? scope.row.invoiceNo : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeCurriculumInfo(scope.row)"
                    >查看订单</el-button
                  >
                  <el-button
                    v-if="
                      scope.row.paymentMethod == '5' &&
                        scope.row.orderStatus == 0
                    "
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="cancellationOfOrder(scope.row)"
                    >确认已付款</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <PartnerCourseTable ref="PartnerCourseTable" />
      </div>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="seeInfologVisible"
      :before-close="closeSeeInfologVisible"
      width="1000px"
    >
      <h3 class="h3">订单详情：</h3>
      <el-row :gutter="20">
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>订单编号：</span>
            <span>{{ infologData.orderSn }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>订单支付编号：</span>
            <span>{{ infologData.orderPaymentNumber }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>支付金额：</span>
            <span>{{
              infologData.paymentMoney == 0 ? "--" : infologData.paymentMoney
            }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>支付时间：</span>
            <span>{{ infologData.payTime }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>订单时间：</span>
            <span>{{ infologData.orderTime }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>订单状态：</span>
            <span>{{
              $setDictionary("ORDER_STATUS", infologData.orderStatus)
            }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>单位名称：</span>
            <span>{{ infologData.compName }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>联系人：</span>
            <span>{{ infologData.compUser }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>联系电话：</span>
            <span>{{ infologData.compPhone }}</span>
          </div></el-col
        >
      </el-row>
      <h3 class="h3">课程详情：</h3>
      <el-row :gutter="20">
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>课程信息：</span>
            <span>{{ infologData.productName }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>课程单价：</span>
            <span>{{ infologData.productPrice }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>总数量：</span>
            <span>{{ infologData.productCnt }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>金额：</span>
            <span>{{ infologData.orderMoney }}</span>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span>支付方式：</span>
            <span>{{
              $setDictionary("PAYMENT_METHOD", infologData.paymentMethod)
            }}</span>
          </div></el-col
        >
      </el-row>
      <h3 class="h3">回款凭证：</h3>
      <template
        v-if="infologData.fileType == 'PDF' || infologData.fileType == 'pdf'"
      >
        <div id="pdf-content2" style="height: 600px"></div>
      </template>
      <template v-else>
        <el-image
          v-if="infologData.voucherUrl"
          style="width: 300px; height: 156px;"
          :src="infologData.voucherUrl"
          :preview-src-list="[infologData.voucherUrl]"
        >
        </el-image>
      </template>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button class="bgc-bv" size="small" @click="closeSeeInfologVisible"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "Partner/partnerList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchData: {
        orderTme: [], // 订单时间
        paymentTime: [], // 支付时间
        compId: "", // 单位名称
        complete: "", // 是否开票
        paymentMethod: "", // 支付方式
        voucher: "", // 上传凭证
        orderStatus: "", // 交易状态
      },
      // 单位名称 - 下拉数据
      CompanyList: [],
      // 是否开票 - 下拉数据
      invoiceOrNotList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      // 支付方式 - 下拉数据
      paymentMethodList: [],
      // 是否上传凭证 - 下拉数据
      uploadVoucherList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      // 交易状态 - 下拉数据
      transactionStatusList: [],
      // 查看订单详情 - 弹框
      seeInfologVisible: false,
      // 订单详情数据
      infologData: {},
      //总计数据
      TotalForm: {
        orderAmountCount: 0,
        orderNumTotal: 0,
      },
    };
  },
  created() {
    this.getPaymentMethodList();
    this.getTransactionStatusList();
    this.getTableHeight();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取 - 单位名称 - 下拉数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 支付方式 - 下拉数据
    getPaymentMethodList() {
      const evaluateCertPaymentList = this.$setDictionary(
        "PAYMENT_METHOD",
        "list"
      );
      const CertPaymentList = [];
      for (const key in evaluateCertPaymentList) {
        CertPaymentList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
      this.paymentMethodList = CertPaymentList;
    },
    // 获取 - 交易状态 - 下拉数据
    getTransactionStatusList() {
      const evaluateCertPaymentList = this.$setDictionary(
        "ORDER_STATUS",
        "list"
      );
      const CertPaymentList = [];
      for (const key in evaluateCertPaymentList) {
        CertPaymentList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
      this.transactionStatusList = CertPaymentList;
    },
    // 获取 - 订单列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.orderTme) {
        params.orderDateStart = this.searchData.orderTme[0];
        params.orderDateEnd = this.searchData.orderTme[1];
      }
      if (this.searchData.paymentTime) {
        params.payDateStart = this.searchData.paymentTime[0];
        params.payDateEnd = this.searchData.paymentTime[1];
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.complete !== "") {
        params.complete = this.searchData.complete;
      }
      if (this.searchData.paymentMethod) {
        params.paymentMethod = this.searchData.paymentMethod;
      }
      if (this.searchData.voucher !== "") {
        params.voucher = this.searchData.voucher;
      }
      if (this.searchData.orderStatus) {
        params.orderStatus = this.searchData.orderStatus;
      }
      this.doFetch({
        url: "/biz/hospital/order/page",
        params,
        pageNum,
      });
      this.getCount();
    },
    // 查看课程 - 弹框展示
    seeCurriculumInfo(row) {
      this.$post("/biz/hospital/order/detail", {
        orderId: row.orderId,
      }).then((res) => {
        if (res.status == "0") {
          this.seeInfologVisible = true;
          this.$set(this, "infologData", res.data);
          if (res.data.fileType == "PDF" || res.data.fileType == "pdf") {
            this.$nextTick(() => {
              pdf.embed(res.data.voucherUrl, "#pdf-content2", {
                pdfOpenParams: {
                  scrollbars: "0",
                  toolbar: "0",
                  statusbar: "1",
                },
              });
            });
          }
        }
      });
    },
    // 取消 - 查看课程 - 关闭弹框
    closeSeeInfologVisible() {
      this.seeInfologVisible = false;
    },
    // 确认付款
    cancellationOfOrder(row) {
      this.$post("/biz/hospital/order/set/paid", {
        orderId: row.orderId,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getData();
        }
      });
    },
    //搜索条件参数
    getParams() {
      const params = {};
      if (this.searchData.orderTme) {
        params.orderDateStart = this.searchData.orderTme[0];
        params.orderDateEnd = this.searchData.orderTme[1];
      }
      if (this.searchData.paymentTime) {
        params.payDateStart = this.searchData.paymentTime[0];
        params.payDateEnd = this.searchData.paymentTime[1];
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.complete !== "") {
        params.complete = this.searchData.complete;
      }
      if (this.searchData.paymentMethod) {
        params.paymentMethod = this.searchData.paymentMethod;
      }
      if (this.searchData.voucher !== "") {
        params.voucher = this.searchData.voucher;
      }
      if (this.searchData.orderStatus) {
        params.orderStatus = this.searchData.orderStatus;
      }
      return params;
    },
    //导出
    getExport() {
      this.$post("/biz/hospital/order/exportOrder", this.getParams())
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //获取订单总数
    getCount() {
      this.$post("/biz/hospital/order/orderTotalCount", this.getParams()).then(
        (ret) => {
          if (ret.status == 0) {
            this.TotalForm.orderAmountCount = ret.data.orderAmountCount;
            this.TotalForm.orderNumTotal = ret.data.orderNumTotal;
          }
        }
      );
    },
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 5.85) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.h3 {
  margin-bottom: 20px;
}
.el-row {
  margin-bottom: 20px;
}
</style>
